import { cx } from "@linaria/core";
import { useMutation, useQuery } from "react-query";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { fetchProductById, fetchProductsList } from "@/api/productsAPI";
import { ButtonToggleCompares } from "@/components/Products/parts/ButtonToggleCompares";
import { SharedText } from "@/components/SharedText/SharedText";
import { useProduct } from "@/hooks/product/product";
import { useWindowSize } from "@/hooks/useWindowSize";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { Button } from "@/ui/Button/Button";
import { EntityImage, ImagePropsType } from "@/ui/EntityImage/EntityImage";
import { Modal } from "@/ui/Modal/Modal";
import { sortProductsWeightRule } from "@/utils/common/helpers";
import { VIEW_PRODUCTS_GRID } from "@/utils/constants";
import { IMAGE_CONFIG } from "components/Products/constants";
import type { ApiError, ProductCatalogType } from "../../../../../contracts";
import Available from "../../../Available/Available";
import { AddToCartControl, Badges, ButtonToggleFavorite, Counter, cssMediaModal, GallerySliderPropsType, ImageContainer, PreviewSlider, Price, StoresAvailablePropsType, Title, UnitControl, Variations } from "../../parts";
import { CodeArticleInfo } from "../../parts/CodeArticleInfo";
import { PopoverSimilar } from "../../parts/PopoverSimilar";
import { ProductWrapper } from "../../ProductWrapper";
import { cssProductPreview, cssZoomIn, ProductContainer, ProductDetailInfo, StyledFloatControls } from "../../StyledProduct";
import type { ViewProductsType } from "../../types";
const ListInfo = dynamic(((() => import("../../parts").then(mod => mod.StoresAvailable)) as DynamicOptions<StoresAvailablePropsType>));
const AfterAddedToCartKit = dynamic(() => import("../../parts").then(mod => mod.AfterAddedToCartKit), {
  ssr: false
});
const GallerySlider = dynamic(((() => import("../../parts").then(mod => mod.Gallery)) as DynamicOptions<GallerySliderPropsType>), {
  ssr: false
});
type CatalogProductPropsType = {
  product: ProductCatalogType;
  view: ViewProductsType;
  index?: number;
  isShared?: boolean;
  isPreview?: boolean;
  sortWeight?: number;
  categories?: string;
  disabledHover?: boolean;
  image?: Pick<ImagePropsType, "width" | "height">;
};
const Product: FC<CatalogProductPropsType & {
  favoritesGroupId?: string | null;
}> = ({
  product,
  view,
  isShared = false,
  isPreview = false,
  sortWeight,
  categories,
  disabledHover = false,
  image: imageConfig,
  favoritesGroupId,
  ...props
}) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductCatalogType>(product);
  const {
    mutate: detailProductMutate,
    isLoading: detailProductIsLoading
  } = useMutation(fetchProductById, {
    onSuccess: result => {
      setSelectedProduct(result);
    },
    onError: (error: ApiError) => {
      console.error(error.message);
    }
  });
  const {
    isFavorites,
    availableStatus,
    units,
    updateCurrentCount,
    updateCurrentUnit,
    totalQty,
    priceCalculate,
    currentUnit,
    isFetching,
    addToCart,
    inCart,
    isRemoved,
    storesQty,
    storesAvailable,
    counter,
    currentCount,
    isCountError: isCountProductError,
    setIsCountError,
    isInit: isInitProduct,
    isAdded,
    kitParents,
    setIsAdded,
    uuid,
    priceUnit,
    kit,
    unitMeasure,
    isKit,
    isBestseller,
    alias,
    isNew,
    name,
    images,
    path,
    isAvailable,
    analogs: analogsConcated,
    analogsIds,
    code,
    article,
    isCompares,
    toggleCompares,
    variations,
    isVariate,
    share,
    sharePath,
    setIsShowShare,
    isShowShare,
    description
  } = useProduct({
    product: {
      ...selectedProduct
    },
    options: {
      isSaveOnRemove: false,
      initQty: selectedProduct.initQty
    }
  });
  const [isHover, setIsHover] = useState<boolean>(false);
  const productRef = useRef<HTMLDivElement>(null);
  const isInitEntity = useMemo(() => {
    return isInitProduct && uuid !== null;
  }, [isInitProduct, uuid]);
  const companionsIds = (product?.companions || []).map(item => item.uuid || "");
  const {
    data: kitProducts
  } = useQuery(["productAnalogs", isAdded, kit], () => fetchProductsList({
    uuids: kit.join(",")
  }), {
    enabled: isAdded && kit.length > 0
  });
  const {
    data: analogsData
  } = useQuery(["productAnalogs", isAdded, analogsIds], () => fetchProductsList({
    uuids: analogsIds.join(",")
  }), {
    enabled: isAdded && analogsIds.length > 0
  });
  const {
    data: companionsData
  } = useQuery(["companions", isAdded, companionsIds], () => fetchProductsList({
    uuids: companionsIds.join(",")
  }), {
    enabled: isAdded && companionsIds.length > 0
  });
  const analogs = useMemo(() => !!analogsData ? sortProductsWeightRule({
    products: analogsData,
    rules: analogsConcated
  }) : [], [analogsData, analogsConcated]);
  const companions = useMemo(() => !!companionsData ? sortProductsWeightRule({
    products: companionsData,
    rules: product?.companions
  }) : [], [companionsData, product?.companions]);
  const {
    width
  } = useWindowSize();
  const isLessMd = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const onMouseEventHandle = useCallback((value: boolean) => {
    if (!isInitEntity || view !== VIEW_PRODUCTS_GRID || disabledHover || isLessMd) {
      setIsHover(false);
      return;
    }
    setIsHover(value);
  }, [disabledHover, isInitEntity, isLessMd, view]);
  const onMouseEnterHandle = () => {
    onMouseEventHandle(true);
  };
  const onMouseLeaveHandle = () => {
    onMouseEventHandle(false);
  };
  const isSliderImages = view === VIEW_PRODUCTS_GRID && images.length > 1;
  const withBadges = isBestseller || isKit || isNew;
  const onSelectVariationHandle = useCallback(({
    uuid = ""
  }) => {
    if (!uuid) {
      return;
    }
    detailProductMutate({
      uuid: uuid
    });
  }, [detailProductMutate]);
  useEffect(() => {
    if (isLessMd) {
      onMouseLeaveHandle();
    }
  }, [isLessMd]);
  return <ProductWrapper {...props} uuid={uuid || undefined} data-alias={alias} sortWeight={sortWeight} categories={categories} isHover={isHover} isFavorite={isFavorites} viewProductsVariant={view} isAvailable={isAvailable} availableStatus={availableStatus || undefined} withBadges={withBadges} onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} isVariate={isVariate} className={cx(isPreview && cssProductPreview)} isLoading={!isInitEntity} ref={productRef} style={{
    minHeight: isHover ? productRef?.current?.offsetHeight : undefined
  }}>
      <ProductContainer>
        <Badges isBestseller={isBestseller} isKit={isKit} isNew={isNew} />

        <StyledFloatControls>
          <ButtonToggleFavorite isProductCard productUUID={uuid ? uuid : ""} groupId={favoritesGroupId} isFavorites={isFavorites} />
          <ButtonToggleCompares isCompares={isCompares} toggleCompares={toggleCompares} />
          <PopoverSimilar productUUID={uuid ? uuid : ""} share={share} companions={product.companions} analogs={product.analogs?.fast} />
        </StyledFloatControls>

        <ImageContainer path={path} isSlider={isSliderImages} name={name ?? undefined}>
          {isSliderImages ? <PreviewSlider alt={name || ""} images={images} url={path} layout={"intrinsic"} width={imageConfig?.width || IMAGE_CONFIG.grid.size.width} height={imageConfig?.height || IMAGE_CONFIG.grid.size.height} objectFit={"contain"} quality={IMAGE_CONFIG.grid.quality} /> : <EntityImage imagePath={images.length > 0 ? images[0] : undefined} imageAlt={name || ""} layout={"intrinsic"} width={imageConfig?.width || IMAGE_CONFIG.grid.size.width} height={imageConfig?.height || IMAGE_CONFIG.grid.size.height} objectFit={"contain"} quality={IMAGE_CONFIG.grid.quality} />}

          {images.length > 0 && <Modal closeMode={"destroy"} variant={"full"} disclosure={<Button icon={"ZoomIn"} variant={"box"} className={cx(cssZoomIn)} aria-label={"Увеличить изображение"} />} classNameBackdrop={cssMediaModal}>
              <GallerySlider images={images} />
            </Modal>}
        </ImageContainer>

        <Title name={name || ""} article={article} code={code} path={path} />

        {isVariate && <Variations isFetching={detailProductIsLoading} selected={selectedProduct.alias} items={variations} withAnimatingLabel={false} onSelectCb={onSelectVariationHandle} />}

        <Price price={priceUnit} as={"b"} unitMeasure={unitMeasure} />

        {isAvailable && <>
            {!isPreview && !isShared && <UnitControl setCurrentUnit={updateCurrentUnit} unitMeasure={unitMeasure} units={units} totalQty={totalQty} unitValueActive={currentUnit || undefined} isInitProduct={isInitEntity} />}
            <Counter counter={counter} currentCount={currentCount} currentUnit={currentUnit} initialUnit={+units[0]?.value ?? null} unitMeasure={unitMeasure} maxCount={totalQty} isFetching={isFetching} updateCountHandler={updateCurrentCount} productInCart={inCart} productIsRemove={isRemoved} isCountError={isCountProductError} setIsCountError={setIsCountError} isInitProduct={isInitEntity} isStatic={isShared && !inCart || isPreview} />
          </>}

        {!isPreview && <AddToCartControl uuid={uuid || null} isAvailable={isAvailable} isFetching={isFetching} inCart={inCart && !isRemoved} addToCart={addToCart} buttonAreaContent={<>
                <Price variant={"total"} price={priceCalculate} />
              </>} />}

        {isPreview && <Price variant={"total"} price={isAvailable ? priceCalculate : 0} messageImportant={isRemoved && isAvailable ? undefined : "нет в наличии"} />}

        <ListInfo storesQty={storesQty} stores={storesAvailable} />

        {isAvailable && <Available status={availableStatus} />}

        {isAvailable && <>
            {!isLessMd && <ProductDetailInfo>
                <CodeArticleInfo code={code} article={article} />
              </ProductDetailInfo>}
          </>}

        {isKit && <Modal closeMode={"destroy"} variant={"rounded-100"} isShowModal={isAdded} title={"Добавлен в корзину"} hideOnClickOutside={false}>
            <AfterAddedToCartKit product={product} companions={companions} kitProducts={kitProducts || []} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
          </Modal>}

        {kitParents.length > 0 && <Modal closeMode={"destroy"} variant={"rounded-100"} isShowModal={isAdded} title={"Добавлен в корзину"} hideOnClickOutside={false}>
            <AfterAddedToCartKit product={product} companions={companions} kitParents={kitParents || []} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
          </Modal>}

        {!!sharePath && <SharedText isShowed={isShowShare} setIsShowed={setIsShowShare} share={{
        url: sharePath,
        title: name ?? "",
        text: description ?? ""
      }} />}
      </ProductContainer>
    </ProductWrapper>;
};
Product.displayName = "Product";
export { Product };
export type { CatalogProductPropsType };